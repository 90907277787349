<template>
  <div>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基本资料</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.c_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addcustomerDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="customerlist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="客户编码" prop="c_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="公司名称" prop="c_name" show-overflow-tooltip width="200px"></el-table-column>
        <el-table-column label="业务员" prop="c_salesman" show-overflow-tooltip></el-table-column>
        <el-table-column label="详细地址" prop="region" show-overflow-tooltip width="200px"></el-table-column>
        <el-table-column label="结算方式" prop="c_settlement" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" size="mini" icon="el-icon-edit"
                       @click="showEditDialog(scope.row.c_id)" v-if="is_edit"></el-button>
            <!--删除-->
            <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                       @click="removeWareById(scope.row.c_id)" v-if="is_del"></el-button>

          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.pagenum"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>

  </div>
</template>
<script>
export default {
  data() {
    return{
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        c_name:''
      },
      total:0,
      //页面权限
      customerJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      // 获取客户信息数据
      customerlist:[],

      // 获取编辑客户信息数据
      editcustomerForm: {},
      //控制编辑对话框的显示与隐藏
      editcustomerDialogVisible:false,
    }
  },
  created() {
    this.getcustomerList()
    this.getcustomerJurisdiction()
  },
  methods: {
    //获取权限接口
    async getcustomerJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.customerJurisdiction=res.data
      this.customerJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    async getcustomerList() {
      this.loading=true
      const {data: res} = await this.$http.get('customer/getList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给catelist
      this.customerlist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
      this.loading=false
    },

    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getcustomerList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getcustomerList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getcustomerList()
    },
    // 编辑 监听级联选择器 选中节点变化时触发
    editWareHouseChanged(){
      this.WareEditForm.c_provice=this.editselectListarea[0]
      this.WareEditForm.c_city=this.editselectListarea[1]
      this.WareEditForm.c_area=this.editselectListarea[2]
    },
    addcustomerDialog(){
      this.$router.push('/customer/addcustomer')

    },
    //编辑
    showEditDialog(id){
      this.$router.push({path: "/customer/editcustomer", query: {editid: id}})
    },

    //删除
    async removeWareById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`customer/del/${id}`)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getcustomerList()
    }


  }
}
</script>
